<template>
  <div class="container">
    <div class="row ">
      <div class="col-12 text-center">
        <h1>Detail Training Report</h1>
      </div>
    </div>
    <div class="row justify-content-between mb-1">
      <div class="col-8">
        <button class="btn btn-primary" @click="addPage">Add New Page</button>
        <button class="btn btn-primary ml-2" @click="questionModal">
          Open Questions List
        </button>
      </div>

      <div class="col-4 text-right">
        <button class="btn btn-primary" @click="printPage">Print to PDF</button>
      </div>
    </div>

    <div id="pagesContainer">
      <div
        v-for="(page, pageIndex) in pages"
        :key="pageIndex"
        class="a4-page"
        :id="'page' + (pageIndex + 1)"
      >
        <div v-if="pageIndex === 0">
          <div class="row  justify-content-between">
            <div class="col-4 ml-3 pt-3">
              <img :src="imageSrc" alt="" width="350" />
            </div>
            <div class="col-4 text-right mr-3 pt-3" style="font-weight: 700;">
              Country Office Pakistan<br />Monitoring & Evaluation Unit
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-6 text-center rep-text">
              Training Analysis Report
            </div>
          </div>

          <div class="row ml-2">
            <div class="col-12">
              <h3 class="cl-b">
                {{ trainingDetails?.title }}
              </h3>
            </div>
            <div class="col-12 cl-b">
              <h6>Training Brief Information</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <template
                      v-if="
                trainingDetails != '' &&
                  trainingDetails.district != undefined 
              "
                    >
                      <th scope="col">Country</th>
                      <td>
                        {{ trainingDetails.district.province.country?.title }}
                      </td>
                      <th scope="col">Province</th>

                      <td>{{ trainingDetails.district.province?.title }}</td>

                      <th scope="col">City</th>
                      <td>{{ trainingDetails.district?.title }}</td>
                    </template>
                    <template v-else>
                      <th scope="col">Country</th>
                      <td>No country added</td>
                      <th scope="col">Province</th>

                      <td>No province added</td>

                      <th scope="col">City</th>
                      <td>No City added</td>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Advisor</th>
                    <td>{{ trainingDetails.advisor?.name }}</td>
                    <th scope="row">Program Officer</th>
                    <td>{{ trainingDetails.programofficer?.name }}</td>
                    <th scope="row">Donor</th>
                    <td>
                      <template v-for="(doner) in doners">
                        {{ doner.title }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Start Date</th>
                    <td>{{ trainingDetails?.startdate }}</td>
                    <th scope="row">End Date</th>
                    <td>{{ trainingDetails?.enddate }}</td>
                    <th scope="row">No. of Days</th>
                    <td>{{ trainingDetails?.noofdays }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Sub Programme/Project</th>
                    <td colspan="5">{{ trainingDetails.subprogram?.title }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Trainers</th>
                    <td colspan="5">
                      <template v-for="(trainer) in alltrainers">
                        {{ trainer.trainer?.name }} ,</template
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12 cl-b ml-3 rep-text">
              Detailed Overall Training Analysis
            </div>

            <div class="col-12 department">
              <div class="row">
                <div class="col-6">
                  <table class="table table-bordered mb-1">
                    <thead>
                      <tr>
                        <th>Departments</th>
                        <th>No</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(depart, dida) in departmentReport?.Dreport"
                        :key="dida"
                      >
                        <td style="padding:0rem 0.75rem">
                          {{ depart?.title }}
                        </td>
                        <td style="padding:0rem 0.75rem">
                          {{ depart?.count }}
                        </td>
                      </tr>

                      <tr>
                        <td style="padding:0rem 0.75rem">Other</td>
                        <td style="padding:0rem 0.75rem">
                          {{ departmentReport.other }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-6">
                  <table class="table table-bordered mb-1">
                    <thead>
                      <tr>
                        <th>Participants Designation</th>
                        <th>No</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(desig, kajds) in designationReport?.DesignationReport"
                        :key="kajds"
                      >
                        <td style="padding:0rem 0.75rem">{{ desig?.title }}</td>
                        <td style="padding:0rem 0.75rem">{{ desig?.count }}</td>
                      </tr>
                      <tr>
                        <td style="padding:0rem 0.75rem">Other</td>
                        <td style="padding:0rem 0.75rem">
                          {{ designationReport?.other }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-for="(chart, chartIndex) in page.charts"
          :key="chartIndex"
          class="chart-box"
        >
          <div
            v-if="chart.checked"
            class="chart-container"
            :id="'chart' + (chart.id + 1) + '-' + (chartIndex + 1)"
          >
            <button
              class="delete-btn"
              @click="removeChart(pageIndex, chartIndex)"
            >
              ×
            </button>
            <h6>{{ chart.questionTitle }}</h6>
            <canvas
              :id="'chartCanvas' + (pageIndex + 1) + '-' + (chartIndex + 1)"
            ></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="modalQuestion" v-if="isModalOpen">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">All Questions</h4>
            <button type="button" class="close" @click="closeModal">
              &times;
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <div class="questions_list">
              <div
                v-for="(chart, chartIndex) in allData"
                :key="chartIndex"
                class="form-check"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="'checkbox' + chart.id"
                  v-model="chart.checked"
                  @change="toggleChart(chart.id)"
                />
                <label class="form-check-label" :for="'checkbox' + chart.id">
                  {{ chart.questionTitle }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/themes/base/all.css';
import axios from 'axios';

export default {
    data() {
        return {
            isModalOpen: false,
            pages: [{
                charts: [],
            }, ],
            allData: [],
            trainingCode: null,
            token: null,
            basepath: null,
            trainingID: '',
            trainingDetails: [],
            doners: [],
            alltrainers: [],
            departmentReport: [],
            designationReport: [],
            imageSrc: require('@/assets/unodc_logo.png'),
        };
    },

    mounted() {
        this.openLoading();
        const urlParams = new URLSearchParams(window.location.search);
        this.trainingCode = urlParams.get('trainingCode');
        this.token = urlParams.get('token');
        this.basepath = urlParams.get('basepath');

        axios.defaults.headers.common['Authorization'] = `${this.token}`;
        axios.get(`${this.basepath}/programOfficer/trainingsingle/get/${this.trainingCode}`)
            .then(response => {

                if (response.data && response.data.training) {
                    this.trainingDetails = response.data.training;
                    this.trainingID = response.data.training.id;
                    this.showTrainers(response.data.training.id);
                    this.showMaleFemale(response.data.training.id);
                    this.DepartmentData(response.data.training.id);
                    this.DesignationData(response.data.training.id);
                    this.allStandardQuestions(response.data.training.id);
                    this.actualKnowledgechange(response.data.training.id);
                    this.allStandardPostQuestions(response.data.training.id);

                    if (response.data && response.data.doner) {
                        this.doners = response.data.doner;
                    }
                }
            });

        setTimeout(() => {
            this.allData.forEach(question => {
                this.pages[0].charts.push(question)
            });
            this.initializeCharts(0);
            setTimeout(() => {
                this.closeLoading();
                this.addPage();
            }, 1000);
        }, 2000);

    },
    methods: {

        initializeCharts(pageIndex) {

            this.pages[0].charts.forEach((data, chartIndex) => {
                this.initializeChart(data, pageIndex, chartIndex);
            });
            this.applyDraggableResizable();
        },
        initializeChart(data, pageIndex, chartIndex) {
            this.$nextTick(() => {
                let chartType = '';
                let colors = [];

                if (data.label.length === 2) {
                    if (data.type === 'bar') {
                        chartType = 'bar';
                        colors = ['#80ff80', '#63b94b'];
                    } else {
                        chartType = 'pie';
                        if(data.label.includes('Male') && data.label.includes('Female')){
                             colors = ['#4774B8', '#EA7D3A']
                        }else{
                          colors = ['#71da71', '#ff5c33']
                        }

                    }
                } else if (data.label.length === 3) {
                    chartType = 'bar';
                   if (data.label.includes('Easy') && data.label.includes('Understandable') && data.label.includes('Difficult to understand')) {
                        colors = ['#92AD0C', '#C3E323', '#FF0000'];
                    } else {
                        // Default colors for other cases where label.length === 3
                        colors = ['#FF0000', '#FFAA01', '#92AD0C'];
                    }
                } else if (data.label.length === 4) {
                    chartType = 'bar';
                    colors = ['#C3E323', '#FFAA01', '#92AD0C', '#6AAA4A'];
                } else if (data.label.length === 5) {
                    chartType = 'bar';
                    colors = ['#FF0000', '#F78B8B', '#FFAA01', '#C3E323', '#92AD0C'];
                }

                const ctx = document.getElementById(`chartCanvas${pageIndex + 1}-${chartIndex + 1}`).getContext('2d');

                const total = data.Options.reduce((sum, val) => sum + val, 0);
                const percentageData = data.Options.map(value => (value / total) * 100);

                const chart = new Chart(ctx, {
                    type: chartType,
                    data: {
                        labels: data.label,
                        datasets: [{
                            label: data.questionTitle,
                            data: percentageData,
                            backgroundColor: colors,
                            categoryPercentage: .9,
                            // minBarLength: 0,

                        }],
                    },
                    options: {
                        scales: {
                            yAxes: [{
                                ticks: {

                                    min: 0,
                                    max: 100,
                                    stepSize: 20
                                },

                            }]
                        },
                        plugins: {
                            datalabels: {
                                formatter: (value, ctx) => {
                                    return Math.round(value) + '%';

                                },
                                color: '#fff',
                                font: {
                                    weight: 'bold',
                                    size: 10,
                                },
                            },
                        },
                        legend: {
                            onClick: (e) => e.stopPropagation()
                        },
                        animation: {
                            onComplete: function () {

                                const CanvasId = document.getElementById(`chart${data.id + 1}-${chartIndex + 1}`)

                                if (CanvasId) {

                                    if (!CanvasId.style.left) {
                                        const left = (chartIndex % 3) * 367;
                                        const top =  Math.floor(chartIndex / 3) * 285;
                                        CanvasId.style.left = `${left}px`;
                                        CanvasId.style.top = `${top}px`;
                                    }

                                }

                            }
                        },
                    },
                    plugins: [ChartDataLabels],
                });

                const chartContainer = $(`#chart${data.id + 1}-${chartIndex + 1}`);
                chartContainer.draggable({
                    containment: 'body',
                }).resizable();

            });
        },
        applyDraggableResizable() {

            this.$nextTick(() => {
                this.pages.forEach((page, pageIndex) => {
                    page.charts.forEach((chart, chartIndex) => {
                        const chartContainer = $(`#chart${chart.id + 1}-${chartIndex + 1}`);
                        chartContainer.draggable({
                            containment: 'body',
                        }).resizable();
                    });
                });
            });
        },

        actualKnowledgechange(tId) {
            axios
                .get(
                    this.basepath +
                    "/training/actual/knowledge-change/get/" +
                    tId
                )
                .then(async response => {

                    if (response) {
                        const knowledgeReport = {
                            label: ['Pre-Training', 'Post-Training'],
                            Options: [response.data.prePercentage, response.data.postPercentage],
                            questionTitle: 'Knowledge Change',
                            type: 'bar',
                            id: 2,
                            checked: true,
                        };

                        this.allData.push(knowledgeReport)

                    }
                });
        },
        allStandardQuestions(tId) {
            axios
                .get(
                    this.basepath +
                    "/training/standardQuestionsNew/all/get/" +
                    tId
                )
                .then(response => {

                    if (
                        response.data
                    ) {
                        let idCounter = 11;
                        response.data.forEach((question, index) => {
                            question.id = idCounter;
                            question.checked = true;
                            this.allData.push(question);
                            idCounter++;
                        });

                    }
                });
        },
        allStandardPostQuestions(tId) {

            axios
                .get(
                    this.basepath +
                    "/training/standardPostQuestionsNew/all/get/" +
                    tId
                )
                .then(response => {

                    if (response.data) {
                        let idCounter = 31;
                        response.data.forEach(question => {
                            question.id = idCounter;
                            question.checked = true;
                            this.allData.push(question);
                            idCounter++;
                        });
                    }
                });

        },

        DepartmentData(tId) {

            axios
                .get(
                    this.basepath +
                    "/single/training/department/data/" +
                    tId

                )
                .then(response => {

                    this.departmentReport = response.data;
                });
        },
        DesignationData(tId) {

            axios
                .get(
                    this.basepath +
                    "/single/training/designation/data/" +
                    tId

                )
                .then(response => {

                    this.designationReport = response.data;
                });
        },
        showTrainers(tId) {
            axios
                .get(
                    this.basepath +
                    "/single/training/trainer/get/" +
                    tId
                )
                .then(response => {
                    this.alltrainers = response.data;

                });
        },
        showMaleFemale(tId) {

            axios
                .get(
                    this.basepath +
                    "/single/training/traineedetails/report/gender/get/" +
                    tId
                )
                .then(response => {

                    const genderReport = {
                        label: ['Male', 'Female'],
                        Options: [response.data.male, response.data.female],
                        questionTitle: 'Gender Distribution',
                        type: 'pie',
                        id: 1,
                        checked: true,
                    };
                    this.allData.push(genderReport);
                });
        },

        toggleChart(chartId) {
            // Find the chart data from allData
            const chart = this.allData.find((item) => item.id === chartId);

            if (chart.checked) {
                // If chart is checked, add it to pages[0].charts (if it's not already there)
                if (!this.pages[0].charts.some((c) => c.id === chartId)) {
                    this.pages[0].charts.push(chart);
                }
            } else {
                // If chart is unchecked, find and destroy the chart instance
                const chartIndex = this.pages[0].charts.findIndex((c) => c.id === chartId);

                if (chartIndex !== -1) {
                    // Remove chart instance before removing the chart
                    const chartInstanceKey = `0-${chartIndex}`;
                    const chartInstance = this.chartInstances[chartInstanceKey];

                    if (chartInstance) {
                        chartInstance.destroy(); // Destroy the chart instance
                        delete this.chartInstances[chartInstanceKey]; // Remove instance reference
                    }

                    // Remove the chart from pages[0].charts
                    this.pages[0].charts.splice(chartIndex, 1);
                }
            }

            // Reinitialize the charts after updating the chart list
            this.$nextTick(() => {
                this.initializeCharts(0);
            });
        },

        addPage() {
            // Add a new page and initialize the charts on that page
            this.pages.push({
                charts: []
            });
            this.$nextTick(() => {
                const newPageIndex = this.pages.length - 1;
                this.initializeCharts(newPageIndex);
            });

        },

        removeChart(pageIndex, chartIndex) {
            // Find and uncheck the corresponding question in allData
            const chart = this.pages[pageIndex].charts[chartIndex];
            const question = this.allData.find(q => q.id === chart.id);
            if (question) {
                question.checked = false;
            }

            // Get the chart instance and destroy it before removing the chart
            const chartInstanceKey = `${pageIndex}-${chartIndex}`;
            const chartInstance = this.chartInstances[chartInstanceKey];
            if (chartInstance) {
                chartInstance.destroy();
                delete this.chartInstances[chartInstanceKey]; // Remove reference to the destroyed instance
            }

            // Remove the chart from the specified page's charts array
            this.pages[pageIndex].charts.splice(chartIndex, 1);

            // Reinitialize the charts after a short delay to ensure DOM updates
            this.$nextTick(() => {
                this.initializeCharts(pageIndex);
            });

        },

        printPage() {

            window.print();
        },
        openLoading() {
            this.$vs.loading({
                type: "material",
            });
        },
        closeLoading() {
            this.$vs.loading.close();
        },
        questionModal() {
            this.isModalOpen = true
        },
        closeModal() {
            this.isModalOpen = false
        },
    },

}
</script>

<style>

.container {
    padding: 20px;
}


.a4-page {
    width: 100%;
    height: 415mm;
    border: 1px solid #000;
    margin-bottom: 20px;
    position: relative;
    background-color: white;

}
.chart-box{
  position: relative
}

.chart-container {
    position: absolute;
    width: 360px;
    height: 280px;
    border: 1px solid #ccc;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    transition: box-shadow 0.3s ease;
    overflow: hidden;
    margin-left: 7px;

}

.chart-container:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    /* Apply box shadow on hover */
}

.delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white !important;
    ;
    border: none;
    cursor: pointer;
    width: 25px;
    height: 35px;
    display: none;
}

.chart-container:hover .delete-btn {
    display: block;
    /* Show the button when hovering over the chart container */
}

.text-right {
    text-align: right;
}

.rep-text {
    font-weight: 600;
    font-size: 22px;
    color: #00a4ea;
}

.cl-b {
    color: #00a4ea;
}

.modalQuestion {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    padding: 10px;
    border: 1px solid #888;
    width: 120%;
}


#page2 {
    z-index: -1;
}

#page3 {
    z-index: -1;
}

@media print {
    body * {
        visibility: hidden;
    }

    #pagesContainer,
    #pagesContainer * {
        visibility: visible;
    }

    #pagesContainer {
        position: absolute;
        left: 4px;
        top: 4px;
        width: 99%;
    }

    .a4-page {
        border: none;
    }

    .ui-resizable-handle {
        display: none
    }

}
</style>
